.daily-checklist {
    height: 100%;
}

.header {
    margin-bottom: 20px;
}

.daily-checklists-content {
    text-align: center;
    margin-top: 20px;
}

.daily-checklist-save-button {
    position:fixed;
    left:0;
    bottom:0;
    width: 100%;
    height: 50px;
    background-color: white;
    text-align: center;
    padding-top: 5px;
}