.hr-documents-email-input-div {
    margin-top: 20px!important;
    margin-right: 15px!important;
    margin-left: 15px!important;
}

.hr-documents-grid-header-row {
    text-align: left;
    background-color: rgba(197, 197, 197, 0.78) !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    height: 40px;
}

.hr-documents-grid-footer-row {
    text-align: left;
    background-color: rgba(225, 225, 225, 0.78) !important;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 44px;
    padding: 4px!important;
}

.hr-documents-grid {
    margin-right: 15px!important;
    margin-left: 15px!important;
    margin-top: 40px!important;
}

.hr-documents-grid-row {
    height: 40px;
    padding: 5px!important;
    border-left: 1px solid rgba(197, 197, 197, 0.78);
    border-right: 1px solid rgba(197, 197, 197, 0.78);
}

.hr-documents-card-grid-row {
    height: 40px;
    padding: 5px!important;
}

.hr-documents-grid-row:hover {background-color: rgba(216, 216, 216, 0.78);}

.hr-documents-send-div {
    margin-top: 50px!important;
    margin-right: 15px!important;
    margin-left: 15px!important;
}

.hr-documents-card-check-button-div {
    margin: 20px!important;
    text-align: center;
}

.hr-documents-card-header {
    text-align: center;
    height: 40px;
    background-color: rgba(225, 225, 225, 0.78) !important;
}

.hr-documents-card-checkbox {
    margin-left: 0!important;
}

.hr-documents-root .ReactTags__tagInput  {
    margin-top: 15px;
}
.hr-documents-root .ReactTags__tags  {
    margin-top: 15px;
}
.hr-documents-root .ReactTags__remove {
    margin-left: 2px!important;
    float: right;
}
.hr-documents-root .ReactTags__tag {
    padding: 10px;
    background-color: rgba(225, 225, 225, 0.78) !important;
    margin-right: 5px;
    display: block;
    width: 300px;
    margin-top: 10px;
}
.hr-documents-root .ReactTags__tagInputField {
    margin: 0;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    text-align: left;
    line-height: 1.21428571em;
    font-family: Lato,Helvetica Neue,Arial,Helvetica,sans-serif;
    padding: .67857143em 1em;
    background: #fff;
    border: 1px solid rgba(34,36,38,.15);
    color: rgba(0,0,0,.87);
    border-radius: .28571429rem;
    transition: box-shadow .1s ease,border-color .1s ease;
    box-shadow: none;
}

.hr-documents-tags-add-button {
    margin-top: 15px!important;
}