.fileInput input{
    width: 250px!important;
    float: left;
    display: block;
}

.fileInputTypes {
    float: left;
    clear:left
}

.schematics-edit-card-header {
    text-align: center;
    height: 40px;
    background-color: #d3d3d3!important;
}

.schematics-edit-rows {
    margin-bottom: 20px!important;
}

.schematics-edit-grid hr {
    width: 90%;
}

.schematics-edit-video-media-div {
    float: left;
    margin: 20px;
    height: 100%;
}
.schematics-edit-images-media-div {
    float: left;
    margin: 20px;
    height: 300px;
}

.schematics-edit-videos {
    max-width: 400px!important;
    max-height: 200px!important;
}

.schematics-edit-images {
    max-height: 400px!important;
    max-width: 200px!important;
    width: 200px;
}

.schematics-edit-remove-image-link {
    margin-top: 5px;
    clear: both;
    text-align: center;
}

@media only screen and (max-width: 600px){
    .schematics-edit-media {
        text-align: center;
    }
    .schematics-edit-video-media-div {
        float: none;
    }
    .schematics-edit-images-media-div {
        float: none;
    }
}

@media only screen and (max-width: 700px){
    .schematics-edit-videos {
        max-width: 220px!important;
    }
    .schematics-edit-images {
        max-height: 220px!important;
        max-width: 220px!important;
    }
    .schematics-edit-images-media-div {
        max-height: 220px;
    }
    .schematics-edit-video-media-div {
        max-height: 220px;
    }
    .fileInputTypes {
        width: 215px!important;
    }
}

@media only screen and (max-width: 355px) {
    .fileInput>input {
        width: 215px!important;
    }
}