.cash-count-content {
    text-align: center;
    margin-top: 20px;
}

.cash-count-sheet {
    height: 100%;
}

.header {
    margin-bottom: 20px;
}

.shift-lead-inputs {
    margin-bottom: 30px;
}

.shiftLeadsCardRow {
    height: 100px;
}

.bar-table input[type=text].cell, .starting-bank-table input[type=text].cell, .cashier-table input[type=text].cell{
    width: 100%;
    padding: 0.5em 0.1em;
    margin: 0.2em;
    border: none;
    border-radius: 0;
    font-size: 1em;
    text-align: right!important;
    display:inline-block;
    position:relative;
}

.bar-table, .starting-bank-table, .cashier-table {
    border-collapse: collapse;
    width: 1300px;
    margin-top: 50px;
    max-width: 100%;
}

.bar-table th, .bar-table td, .starting-bank-table th, .starting-bank-table td, .cashier-table th, .cashier-table td {
    border: 1px solid black;
}

.cash-count-sheet th {
    height: 50px;
    padding: 5px;
}

.shift-cell {
    width: 100px;
}

.cashier-descripiton-header-cell {
    width: 100px;
}

.cashier-descripiton-cell {
    text-align: right!important;
}

.cashier-table-blank-row {
    height: 10px;
}

.cash-count-sheet td {
    font-size: small;
    padding-right: 5px;
    width: 100px;
    height: 40px;
}

.cashiers-note-div {
    margin-top: 40px;
    margin-left: 20%;
    margin-right: 20%;
}

.cashiers-textarea {
    width: 100%;
}

.cash-count-sheet tr:hover {background-color:#fff0e6;}
.cash-count-sheet tr:nth-child(even) {background-color: #fff0e6;}

.bar-table-top-left-cell, .bar-table-top-left-second-cell, .bar-table-top-right-cell {
    background: repeating-linear-gradient( 45deg, transparent, transparent 10px, white 10px, white 20px ),
        /* on "bottom" */ linear-gradient( to bottom, #ffd1b3, #ffd1b3 );;
}

.bar-table-bills-coins {
    background-color: #ffc299!important;
}
.starting-banks-table-div th, .cashiers-table-div th {
    background-color: #ffc299!important;
}

@media only screen and (max-width: 1350px) {
    .bar-table-div{
        width: 100%;
        overflow-x: scroll;
    }
    .cashiers-table-div{
        width: 100%;
        overflow-x: scroll;
    }
    .starting-banks-table-div{
        width: 100%;
        overflow-x: scroll;
    }
}