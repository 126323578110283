.address-book-edit-checkbox-column {
    text-align: center;
    margin-bottom: 15px!important;
}

.address-book-edit-categories-div {
    margin-top: 50px!important;
}

.address-book-edit-inputs-div {
    margin-top: 50px;
    margin-bottom: 20px;
}

.address-book-edit-stores {
    margin-top: 20px;
    margin-bottom: 20px;
}

.address-book-edit-stores b{
    margin-right: 10px;
}