.checklists-admin-types-select {
    text-align: center;
}

.checklists-admin-table-div {
    margin-top: 50px;
}

.checklists-admin-dragdrop-divs {
    min-height: 70px;
    border-bottom: 1px solid lightgrey;
    padding: 20px!important;
}

.checklists-admin-grid-action-columns {
    text-align: center;
}

.checklists-admin-grid-columns {
    padding-top: 5px!important;
}

@media only screen and (max-width: 768px) {
    .checklists-admin-stores {
        text-align: center;
    }
}