.filters {
    text-align: center;
    margin-bottom: 20px;
}

.filters .col-left{
    text-align: left;
}

.filters .col-right{
    text-align: right;
}

.filters .filters-datepicker{
    margin-top: 10px;
}

.filters-datepicker .clndr-left {
    left: -30% !important;
}

.store-info-icon {
    margin-top: 15px;
}

.clndr-calendars-segment {
    z-index: 1500!important;
}

.store-info-popup-content h5 {
    margin-top: 10px;
}
.store-info-popup-content a {
    color: orange!important;
    text-decoration: none!important;
}
.stores-info-modal a {
    color: orange!important;
    text-decoration: none!important;
}

.store-info-managers p {
    margin:0!important;
}

.visible.transition {
    margin-top: auto;
    display: inline-block !important;
    position: relative;
    top: 10%;
}

.store-info-content{
    text-align: right;
    margin-top: 15px;
}

.store-info-content .info-row{
    margin-top: 5px;
}

.store-info-content .info-label:after{
    content: " : ";
}

.store-info-content .info-value a{
    color: rgba(0,0,0,.87);
}

.filters-store-info .store-number{
    text-align: right;
}

@media only screen and (max-width: 768px) {
    .modal-stores-info {
        text-align: left!important;
    }

    .stores-info-modal {
        width: 280px!important;
    }

    .modal-stores-info p, .modal-stores-info h5 {
        margin: 0!important;
    }
}
@media only screen and (min-width: 768px) {
    .store-name {
        float: left;
    }
    .filters .ui.icon.buttons {
        float: left;
    }
    .filters-store-info {
        float: right;
    }
}

.welcome-msg{
    font-weight: normal;
}