.key-release-content {
    clear: both;
    margin-top: 60px;
}

.key-release-types-div {
    text-align: center;
}

.key-release-types-checkbox {
    margin: 10px;
}

.key-release-types-button-div {
    margin-top: 20px;
}

.key-release-table th, .key-release-table td{
    border: 1px solid black;
    text-align: center;
    height: 30px;
}

.key-release-table-header-building {
    border-right: 0!important;
}

.key-release-table-header-office {
    border-right: 0!important;
    border-left: 0!important;
}

.key-release-table-header-gate {
    border-left: 0!important;
}

.key-release-table {
    border-collapse: collapse;
    width: 100%;
    min-width: 768px;
}

.key-release-table-header {
    background-color: #d3d3d3 !important
}

.key-release-table-div {
    margin-top: 40px;
}

.key-release-table input[type=text].cell{
    width: 98%;
    padding: 0.5em 0.1em;
    margin: 0.2em;
    border: none;
    border-radius: 0;
    font-size: 1em;
    text-align: left!important;
    display:inline-block;
    position:relative;
}

.user-info-input-form-field .field {
    width: 100%!important;
}

.user-info-input-form-field .field .field {
    margin-bottom: 0px!important;
}

@media only screen and (max-width: 1260px) {
    .key-release-table {
        width: 1300px;
    }

    .key-release-table-div {
        overflow-x: scroll;
    }
}
@media only screen and (max-width: 768px) {
    .key-release-stores {
        text-align: center;
    }
}