.products-admin-edit-fields {
    margin-top: 50px;
}

.products-admin-edit-categorys {
    margin-left: 10px;
}

.products-admin-edit-file-input-div {
    margin-top: 20px;
}

.products-admin-edit-buttons-div {
    clear: left;
}

.products-edit-images-media-div {
    clear: left;
    max-height: 400px;
    max-width: 200px;
    text-align: center;
    margin-bottom: 20px;
}

.products-edit-images {
    max-height: 400px!important;
    max-width: 200px!important;
}

.products-admin-edit-file-input-div .fileInputTypes {
    margin-bottom: 20px;
}