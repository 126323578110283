.recipes-admin-card-header {
    text-align: center;
    background-color: #d3d3d3 !important;
    height: 40px;
}

.select-recipe-div {
    text-align: center;
    width: 100%;
}

.recipes-admin-list-item {
    max-height: 50px;
    padding: 5px!important;
    overflow: hidden;
}

.recipes-admin-dragdrop-divs {
    margin: 0 0 8px!important;
}

.recipes-admin-dragdrop-divs:last-child {
    margin-bottom: 0!important;
}

.recipes-admin-grid {
    height: 100%;
}

.recipes-admin-grid-columns {
    padding: 10px;
    text-align: left;
}

.recipes-admin-grid-action-columns {
    text-align: right;
}

.recipes-admin-grid-action-save-new-columns {
    float: left;
}

.recipes-admin-grid-action-save-new-rows {
    margin-bottom: 20px!important;
}

.recipes-admin-types-select {
    text-align: center;
}

@media only screen and (max-width: 767px) {
    .recipes-admin-list-item {
        max-height: 100px;
    }
    .recipes-admin-grid-rows {
        margin-bottom: 15px!important;
    }
    .recipes-admin-grid-columns {
        text-align: center;
    }
    .recipes-admin-grid-action-columns {
        text-align: center;
    }
    .recipes-admin-grid-action-save-new-columns {
        float: none;
        text-align: center;
    }
    .add-new-recipe{
        text-align: center;
    }
    .recipes-admin-stores {
        text-align: center;
    }
}