.users-admin-view-header-description-div {
    margin-top: 50px;
}

.users-admin-view-avatar {
    float: left;
}
.users-admin-view-avatar-username {
    float: left;
}

.users-admin-view-links {
    margin-top: 20px;
}

.users-admin-view-buttons {
    margin-top: 20px;
}

.users-admin-view-email-phone-div {
    margin-top: 20px;
}

.users-admin-view-links a {
    color: orange;
}

.users-admin-view-grid {
    margin-right: 15px!important;
    margin-left: 15px!important;
}

.users-admin-view-avatar-text-div {
    float: left;
    padding-top: 30px;
    padding-left: 5px;
}

.users-admin-view-grid-header-row {
    text-align: left;
    background-color: rgba(197, 197, 197, 0.78) !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    height: 40px;
}

.users-admin-view-grid-row {
    height: 40px;
    padding: 5px!important;
    border-left: 1px solid rgba(197, 197, 197, 0.78);
    border-right: 1px solid rgba(197, 197, 197, 0.78);
    padding-left: 0!important;
    padding-right: 0!important;
}

.users-admin-view-grid-row:hover {background-color: rgba(216, 216, 216, 0.78);}

.users-admin-view-grid-footer-row {
    text-align: left;
    background-color: rgba(238, 238, 238, 0.78) !important;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top: 0;
    border-right: 1px solid rgba(197, 197, 197, 0.78);
    border-left: 1px solid rgba(197, 197, 197, 0.78);
    border-bottom: 1px solid rgba(197, 197, 197, 0.78);
    height: 44px;
    padding: 4px!important;
}

.users-admin-view-grid-footer-row .plus {
    background-color: #e0e1e2!important;
}

.users-admin-view-grid-footer-row .button:hover {
    background-color: #e0e1e2!important;
}

.users-admin-view-table {
    border-collapse: collapse;
    width: 100%;
}

.users-admin-view-roles-table {
    border-collapse: collapse;
    width: 100%;
}

.users-admin-view-table th, .users-admin-view-table td{
    border: 1px solid black;
    text-align: center;
    height: 30px;
}

.users-admin-view-roles-table th, .users-admin-view-roles-table td{
    border: 1px solid black;
    text-align: center;
    height: 30px;
}

.users-admin-view-stores-card-description {
    overflow: inherit!important;
}

.users-admin-view-table-header {
    background-color: #d3d3d3 !important
}

.users-admin-view-stores-card-header {
    text-align: center;
    height: 40px;
    background-color: rgba(225, 225, 225, 0.78) !important;
    padding-top: 7px!important;
    padding-bottom: 7px!important;
}

.users-admin-view-roles-card-header {
    text-align: center;
    height: 40px;
    background-color: rgba(225, 225, 225, 0.78) !important;
    padding-top: 7px!important;
    padding-bottom: 7px!important;
}

.users-admin-view-stores-card tr:hover {background-color:rgba(225, 225, 225, 0.78);}

.users-admin-view-roles-card tr:hover {background-color:rgba(225, 225, 225, 0.78);}

.users-admin-view-stores-card-div {
    margin-top: 30px;
    padding-right: 20px;
    width: 70%;
    float: left;
}

.users-admin-view-roles-card-div {
    margin-top: 30px;
    width: 30%;
    float: left;
}

.users-admin-view-table-actions-td {
    background-color: #e0e1e2;
}

.users-admin-view-button {
    margin-top: 15px!important;
}

.users-admin-view-avatar {
    float: left;
}
.users-admin-view-avatar-div b {
    text-transform: uppercase;
    font-size: x-large;
}

.users-admin-view-links {
    clear: both;
}

.users-admin-view-stores-card-actions .selection {
    margin-right: 10px!important;
    margin-bottom: 10px!important;
}

.users-admin-view-stores-card-actions .checkbox  {
    margin-right: 10px!important;
    margin-bottom: 10px!important;
}

.users-admin-view-stores-card-actions .button  {
    margin-bottom: 10px!important;
}

.users-admin-view-avatar-text-div small {
    color: dimgrey;
}

@media only screen and (max-width: 500px) {
    .users-admin-view-avatar-text-div {
        width: 180px;
    }
}
@media only screen and (max-width: 768px) {
    .users-admin-view-stores-card-div {
        margin-top: 30px;
        width: 100%;
        float: none;
        padding-right: 0!important;
    }

    .users-admin-view-table-div {
        overflow-x: scroll;
    }

    .users-admin-view-roles-table-div {
        overflow-x: scroll;
    }

    .users-admin-view-roles-card-div {
        margin-top: 30px;
        width: 100%;
        float: none;
    }

    .users-admin-view-table {
        width: 1300px;
    }
}