.new-orders-product-card {
    margin-top: 20px!important;
}

.product-card-description {
    overflow-x: scroll;
}

.product-card-header {
    text-align: center;
    height: 40px;
    background-color: #d3d3d3!important;
}

.product-table {
    border-collapse: collapse;
    width: 100%;
    min-width: 768px;
}

.product-table-header {
    background-color: #d3d3d3 !important
}

.product-table input[type=text].cell{
    width: 98%;
    padding: 0.5em 0.1em;
    margin: 0.2em;
    border: none;
    border-radius: 0;
    font-size: 1em;
    text-align: right!important;
    display:inline-block;
    position:relative;
}

.new-orders-product-card tr:hover {background-color:#fff0e6;}
.new-orders-product-card tr:nth-child(even) {background-color: #fff0e6;}

.product-table th, .product-table td{
    border: 1px solid black;
    text-align: center;
    height: 30px;
}

.new-orders-extra-history-row {
    height: 70px;
    background-color: #e0e1e2!important;
}

.new-orders-extra-history-cell {
    float: left;
    width: 16.66%;
    height: 70px;
    padding: 15px;
}
.new-orders-extra-history-cell:hover {
    background-color: #d3d3d3!important;
}

.new-orders-product-card:last-child {
    margin-bottom: 40px!important;
}

@media only screen and (max-width: 768px) {
    .product-table {
        width: 1300px;
    }
}