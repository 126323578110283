.retail-score-page {
    height: 100%;
}

.header {
    margin-bottom: 20px;
}

.retail-card-header {
    background-color: #d3d3d3 !important;
    height: 40px;
}

.retail-score-content {
    margin-top: 20px;
}

.retail-card {
    margin-bottom: 30px!important;
}

.add-new-retail-score{
    margin-top: 20px;
}

.retail-score-grid-action{
    margin-top: 20px;
}

.retail-score-divs {
    margin: 0 0 8px!important;
}

.retail-score-divs:last-child {
    margin-bottom: 0!important;
}

.retail-score-error {
    color: red;
}

@media only screen and (max-width: 767.98px) {
    .retail-score-item .retail-score-grid > .retail-score-grid-row{
        flex-wrap: nowrap;
    }

    .retail-score-grid-action-column .retail-score-item .ui.retail-score-grid > .retail-score-grid-row .ten{
        width: 50%!important;
    }

    .retail-score-grid-action-column .retail-score-item .ui.retail-score-grid > .retail-score-grid-row .four{
        width: 30%!important;
    }

    .retail-score-grid-action-column .retail-score-item .ui.retail-score-grid > .retail-score-grid-row .two{
        width: 20%!important;
    }

    .retail-score-grid-column > .ui > .retail-score-grid > .retail-score-grid-row{
        flex-wrap: nowrap;
    }

    .retail-score-grid-column > .ui >  .retail-score-grid .retail-score-grid-row > .retail-score-grid-column.two{
        width: 20%!important;
    }

    .retail-score-grid-column > .ui >  .retail-score-grid .retail-score-grid-row > .retail-score-grid-column.twelve{
        width: 80%!important;
    }
}