.address-book-table th, .address-book-table td{
    border: 1px solid black;
    text-align: center;
    height: 30px;
}

.address-book-table {
    border-collapse: collapse;
    width: 100%;
    min-width: 768px;
}

.address-book-table-header {
    background-color: #d3d3d3 !important
}

.address-book-table-div {
    margin-top: 40px;
}

.address-book-table-header-empty {
    background: repeating-linear-gradient( 45deg, transparent, transparent 10px, white 10px, white 20px ),
        /* on "bottom" */ linear-gradient( to bottom, #d3d3d3, #d3d3d3 );
}

.address-book-table-actions-td {
    text-align: center!important;
}

.address-book-button-div {
    margin-top: 20px;
}

.address-book-table-actions-td {
    background-color: #e0e1e2;
}

.address-book-table-header-empty {
    width: 70px!important;
}

.address-book-table tr:hover {background-color:#e0e1e2;}

.address-book-table-td a {
    color: #0e8c8c;
    cursor: pointer;
}

@media only screen and (max-width: 1260px) {
    .address-book-table-div {
        overflow-x: scroll;
    }
}