@media only screen and (max-width: 768px) {
    .checklists-admin-edit-stores {
        text-align: center;
    }
}

.checklists-admin-edit-root .header {
    margin-bottom: 30px;
}

.checklists-admin-edit-stores {
    margin-bottom: 30px;
}

.checklists-admin-edit-types-select {
    margin-bottom: 30px;
}

.checklists-admin-edit-inputs {
    margin-bottom: 30px!important;
}