.service-ticket-content {
    margin-top: 30px!important;
}

.service-ticket-name-input-div, .service-ticket-text-area-div, .service-ticket-file-input {
    margin-top: 20px!important;
}

.service-ticket-buttons-row {
    margin-top: 30px!important;
}

.service-ticket-media-div {
    height: 400px;
    margin-top: 40px!important;
}

.service-ticket-buttons {
    clear: both;
}

.service-ticket-name-input-div input {
    width: 100%!important;
}

.service-ticket-remove-image-link {
    margin-top: 5px;
    clear: both;
    text-align: center;
    cursor: pointer;
}

.service-ticket-address-book-resorce {
    display: block;
}

.service-ticket-address-book-picker-grid {
    border: 2px solid lightgrey;
    margin-bottom: 30px!important;
    margin-left: 0px!important;
}

.service-ticket-address-book-picker-column {
    margin-top: 15px!important;
    text-align: center;
}

.service-ticket-address-book-picker-column b {
    text-transform: uppercase;
}

.service-ticket-address-book-picker-column a {
    cursor: pointer;
}

.service-ticket-address-book-picker-column a:hover {
    color: orange;
}

.service-ticket-address-book-picked-grid h5{
    margin-bottom: 5px!important;
}
.service-ticket-address-book-picked-grid h3{
    text-transform: uppercase;
}
.service-ticket-address-book-picked-grid {
    background-color: lightgrey;
    text-align: center;
    padding: 15px!important;
    margin-bottom: 30px!important;
    margin-left: 15px!important;
    color: gray;
}

.service-ticket-videos-media-div {
    float: left;
    margin: 20px;
    height: 200px;
}

.service-ticket-videos {
    max-width: 400px!important;
    max-height: 200px!important;
}

.service-ticket-images-media-div {
    float: left;
    margin: 20px;
    height: 200px;
}

.service-ticket-images {
    max-height: 400px!important;
    max-width: 200px!important;
    width: 200px;
}

.service-ticket-buttons {
    clear: both;
    padding-top: 30px;
}

.service-ticket-remove-image-link {
    margin-top: 5px;
    clear: both;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .service-ticket-media {
        text-align: center;
    }
    .service-ticket-videos-media-div {
        float: none;
    }
    .service-ticket-images-media-div {
        float: none;
    }
}

@media only screen and (max-width: 767px){
    .service-ticket-address-book-picker-column{
        text-align: center;
    }

    .service-ticket-address-book-picked-grid {
        margin-left: 0!important;
    }

    .service-ticket-address-book-picked-grid-column {
        padding-left: 0!important;
    }
}
@media only screen and (max-width: 700px){
    .service-ticket-buttons {
        text-align: center;
    }
    .service-ticket-videos-media-div {
        max-height: 220px;
    }
    .service-ticket-videos {
        max-width: 220px!important;
    }
    .service-ticket-images-media-div {
        max-height: 220px;
    }
    .service-ticket-images {
        max-height: 220px!important;
        max-width: 220px!important;
    }
}