.home-announcement-card-header , .home-promotion-card-header {
    background-color: #d3d3d3 !important;
    height: 40px;
    text-align: center;
}

.home-page .header {
    margin-top: 30px;
    margin-bottom: 50px;
}

.home-cards-div, .home-menu-wrap {
    padding-right: 30px;
    padding-left: 30px;
}

.home-menu-wrap .ui.grid{
    margin: 0;
}

.home-menu-wrap .ui.grid>.row>.column{
    box-shadow: none !important;
}

.home-card {
    text-align: center;
}

.home-promotion-card-image-div {
    float: left;
    width: 50%;
    text-align: right;
}

.home-promotion-card-info-div {
    float: left;
    width: 50%;
}

.home-promotion-card-info-div {
    text-align: left;
    padding-left: 10px;
}

.home-promotion-card-info-div a {
    cursor: pointer;
    color: #0e8c8c;
    text-decoration: underline;
}

.home-promotion-card-edit-image-div {
    clear: left;
    margin-top: 50px;
}

@media only screen and (min-width: 490px){
    .home-promotion-card-images{
        max-width: 200px!important;
        max-height: 300px!important;
    }
}

@media only screen and (min-width: 390px) and (max-width: 489px){
    .home-promotion-card-images{
        max-width: 150px!important;
        max-height: 300px!important;
    }
}

@media only screen and (max-width: 389px){
    .home-promotion-card-images{
        max-width: 100px!important;
        max-height: 300px!important;
    }
}

@media only screen and (max-width: 700px){
    .home-cards-div {
        padding-right: 10px;
        padding-left: 10px;
    }
}