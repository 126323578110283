.new-orders-vendors-and-history-and-order {
    text-align: center;
}

.vendor-info-popup-content h5 {
    margin-top: 10px;
}
.vendor-info-popup-content a {
    color: orange!important;
    text-decoration: none!important;
}

.vendors-info-modal a {
    color: orange!important;
    text-decoration: none!important;
}

.new-orders-filters-buttons.ui.icon.buttons {
    float: none!important;
    margin-left: 10px;
    margin-top: 15px;
}

.new-orders-print-button {
    padding-top: 11px;
}

.new-orders-order-select {
    margin-top: 15px;
}

.new-orders-submit-button-div {
    position:fixed;
    left:0;
    bottom:0;
    width: 100%;
    height: 50px;
    background-color: white;
    text-align: center;
    padding-top: 5px;
}

@media only screen and (max-width: 768px) {
    .modal-vendors-info {
        text-align: left!important;
    }

    .vendors-info-modal {
        width: 280px!important;
    }

    .modal-vendors-info p, .modal-vendors-info h5 {
        margin: 0!important;
    }

    .new-orders-stores {
        text-align: center;
    }

    .new-orders-vendors-and-history-and-order {
        text-align: center;
    }

    .new-orders-print-button {
        text-align: center;
    }
}