.pagination {
    list-style: none;
    padding: 0;
    display: flex;
}

.pagination li {
    margin-right: 5px;
}

.pagination li button {
    padding: 8px 12px;
    background-color: #ccc;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.pagination li.active button {
    background-color: #f2711c;
    color: #fff;
}

