.new-document-input-div {
    margin-bottom: 30px;
}

.new-document-button-div {
    clear: both;
    margin-top: 30px!important;
}

.update-document-file-name a {
    color: #0d71bb;
}