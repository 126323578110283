.resource-index-grid.ui.grid {
    margin: 40px 0;
}

.resource-index-grid-header-row {
    text-align: left;
    background-color: rgba(197, 197, 197, 0.78) !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    height: 40px;
}

.retail-score-admin-root .table-filter {
    margin-top: 20px;
}

.resource-index-grid-row {
    min-height: 40px;
    padding: 5px !important;
    border-left: 1px solid rgba(197, 197, 197, 0.78);
    border-right: 1px solid rgba(197, 197, 197, 0.78);
    border-bottom: 1px solid rgba(197, 197, 197, 0.78);
}

.resource-index-grid-row:hover {
    background-color: rgba(216, 216, 216, 0.78);
}

.resource-index-grid-footer-row {
    text-align: left;
    background-color: rgba(238, 238, 238, 0.78) !important;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top: 0;
    border-right: 1px solid rgba(197, 197, 197, 0.78);
    border-left: 1px solid rgba(197, 197, 197, 0.78);
    border-bottom: 1px solid rgba(197, 197, 197, 0.78);
    height: auto;
    padding: 10px;
}

.resource-index-grid-footer-row .plus {
    /* background-color: #e0e1e2 !important; */
}

.resource-index-grid-footer-row .button:hover {
    /* background-color: #e0e1e2 !important; */
}

.resource-index-card-header {
    text-align: center;
    height: 40px;
    background-color: rgba(225, 225, 225, 0.78) !important;
}

.resource-index-card-grid-row {
    height: 100%;
    padding: 5px !important;
}

.resource-index-card-grid {
    padding-bottom: 20px !important;
}

.users-admin-trash-button-div {
    margin-top: 50px;
}

.resource-index-grid-column {
    word-wrap: break-word;
}

.resource-index-grid-actions-column {
    text-align: right;
}

.resource-index-grid-row a {
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
}

.resource-index-card-grid-row a {
    color: #0e8c8c;
}

.resource-index-card-grid-row-buttons-div button {
    margin-top: 10px !important;
}

.action-buttons {
    display: flex;
}

.white {
    color: #fff;
}

.white:hover {
    color: #fff;
}