.recipes-page .accordion .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recipes-page .accordion .title:not(.ui) {
  font-size: 16px;
}

.recipes-page .accordion .title .dropdown.icon {
  float: right;
  margin-left: 10px;
  margin-right: 0;
}

.recipes-page .accordion-content {
  display: flex;
}

.recipes-page .accordion-content .recipes-media {
  width: 100%;
  max-width: 300px;
}

.recipes-page .accordion-content .recipes-content {
  margin-left: 20px;
}

.recipes-images-expand {
  float: right;
  cursor: pointer;
}

.retail-score-card-header {
  background-color: #d3d3d3 !important;
  height: 40px;
}

.retail-score-listing{
  margin-top: 20px;
  column-count: 4;
  column-gap: 20px;
}

.retail-score-card{
  break-inside: avoid;
}

.retail-score-summary{
  margin-top: 60px;
  padding: 60px 0;
  border-top: 1px solid #ccc;
  display: flex;
  flex-wrap: wrap;
}

.additional-info{
  border: 1px solid rgba(34,36,38,.15);
  padding: 20px;
  margin-bottom:15px;
}

.summary-box{
  width: 100%;
  max-width: 420px;
  margin-left: auto;
  margin-right: 0;
  font-size: 16px;
}

.summary-row{
  display: flex;
}

.summary-col {
  width: 100%;
  padding: 5px 0;
}

.summary-total{
  margin-top: 5px;
}

.summary-grand-total{
  margin-top: 30px;
  padding-top: 30px;
  font-size: 20px;
  font-weight: 600;
  border-top: 1px solid #ccc;
}

.total-points{
  margin-top: 20px;
}

.retail-score-info{
  margin-left: 0;
  margin-right: auto;
  max-width: 580px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.score-end-row{
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1200px){
  .retail-score-listing{
    column-count: 3;
  }
  .retail-score-info, .summary-box{
    max-width: 100%;
  }
  .retail-score-summary{
    flex-direction: column-reverse;
  }
  .additional-info, .score-end-row{
    margin-top: 40px;
  }
}

@media (max-width: 991px){
  .retail-score-listing{
    column-count: 2;
  }
}

@media (max-width: 580px){
  .retail-score-listing{
    column-count: 1;
  }
}