.task-column {
    float: left;
}

.task-note-column {
    float: left;
    text-align: left;
}

.daily-checklist-task-text {
    text-align: initial;
}

.weekle-header {
    background-color: #8de2f4 !important;
}

.daily-checklist-task-text-container {
    padding: 20px;
}

.daily-list-card:last-child {
    margin-bottom: 70px!important;
}

@media only screen and (min-width: 768px) {
    .employee-name-column {
        float: right;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .daily-checklist-task-text-container {
        padding-right: 20px!important;
    }
}

@media only screen and (max-width: 767px) {
    .daily-checklist-task-text-container {
        padding-bottom: 0!important;
        padding-top: 25px!important;
    }

    .employee-name-column {
        float: left;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .daily-checklist-name-container {
        padding-top: 0!important;
    }

    .task-note-column {
        float: left;
        clear: both;
    }
}