.promotion-edit-card-fields {
    margin-bottom: 40px;
}

.promotion-edit-card-header {
    text-align: center;
    height: 40px;
    background-color: #d3d3d3!important;
}

.promotion-edit-card-video-media-div {
    float: left;
    margin: 20px;
    height: 300px;
}

.promotion-edit-card-videos {
    max-width: 400px!important;
    max-height: 200px!important;
}

.promotion-edit-card-remove-image-link {
    margin-top: 5px;
    clear: both;
    text-align: center;
}

.promotion-edit-card-images-media-div {
    float: left;
    margin: 20px;
    height: 300px;
}

.promotion-edit-card-images {
    max-height: 400px!important;
    max-width: 200px!important;
    width: 200px;
}

.promotion-edit-card-remove-image-link {
    margin-top: 5px;
    clear: both;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .promotion-edit-card-media {
        text-align: center;
    }
    .promotion-edit-card-video-media-div {
        float: none;
    }
    .promotion-edit-card-images-media-div {
        float: none;
    }
}

@media only screen and (max-width: 700px){
    .promotion-edit-card-video-media-div {
        max-height: 220px;
    }
    .promotion-edit-card-videos {
        max-width: 220px!important;
    }
    .promotion-edit-card-images-media-div {
        max-height: 220px;
    }
    .promotion-edit-card-images {
        max-height: 220px!important;
        max-width: 220px!important;
    }
}