.batch-recipe-card-header {
    background-color: #d3d3d3 !important;
    height: 40px;
    text-align: center;
}

.batch-recipe-card-content {

}

.batch-recipe-card {
    margin-bottom: 30px!important;
}

.batch-recipes-text-div {
    padding: 20px;
}

.batch-recipe-card-content hr {
    width: 90%;
}

.batch-recipes-videos-media-div {
    float: left;
    margin: 20px;
    height: 100%;
}

.batch-recipes-videos {
    max-width: 400px!important;
    max-height: 200px!important;
}

.batch-recipes-images-media-div {
    float: left;
    margin: 20px;
    height: 300px;
}

.batch-recipes-images {
    max-height: 400px!important;
    max-width: 200px!important;
    width: 200px;
}

.recipes-select {
    text-align: center;
}

@media only screen and (max-width: 600px){
    .batch-recipes-videos-media-div {
        float: none;
    }
    .batch-recipes-images-media-div {
        float: none;
    }
}

@media only screen and (max-width: 700px){
    .batch-recipes-videos-media-div {
        max-height: 220px;
    }
    .batch-recipes-videos {
        max-width: 220px!important;
    }
    .batch-recipes-images-media-div {
        max-height: 220px;
    }
    .batch-recipes-images {
        max-height: 220px!important;
        max-width: 220px!important;
    }
}