.schematics-page {
    height: 100%;
}

.header {
    margin-bottom: 20px;
}

.schema-card-header {
    background-color: #d3d3d3 !important;
    height: 40px;
}

.schematics-content {
    text-align: center;
    margin-top: 20px;
}

.schematics-videos-media-div {
    float: left;
    margin: 20px;
    height: 100%;
}

.schematics-images-media-div {
    float: left;
    margin: 20px;
    height: 300px;
}

.schematics-images {
    max-height: 400px!important;
    max-width: 200px!important;
    width: 200px;
}

.schematics-videos {
    max-width: 400px!important;
    max-height: 200px!important;
}

.schema-card-content {
    text-align: center!important;
}

.schema-card {
    margin-bottom: 30px!important;
}

@media only screen and (max-width: 600px){
    .schematics-media {
        text-align: center;
    }
    .schematics-videos-media-div {
        float: none;
    }
    .schematics-images-media-div {
        float: none;
    }
}

@media only screen and (max-width: 700px){
    .schematics-videos {
        max-width: 220px!important;
    }
    .schematics-images {
        max-height: 220px!important;
        max-width: 220px!important;
    }
    .schematics-images-media-div {
        max-height: 220px;
    }
    .schematics-videos-media-div {
        max-height: 220px;
    }
}