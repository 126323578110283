.grid-wrapper{
    max-width: 60%;
    margin: 0 auto;
    text-align: center;
}

.grid-wrapper .menu-item{
    text-align: center;
    display: inline-block;
    margin: 0px auto 50px auto;
}

.grid-wrapper .menu-item .menu-home-icon{
    margin: 0 auto;
}

.grid-wrapper .menu-item .menu-label{
    display: block;
    text-transform: uppercase;
    color: #000000;
    font-size: 18px;
    margin-top: 10px;
}

.text-left{
    text-align: left !important;
}

.menu-home-icon {
    width: 80px;
    height: 80px;
    vertical-align: middle;
    display: inline-block;
    background-size: 100% auto;
    background-repeat: no-repeat;
}

.menu-home-icon.check-lists {
    background-image: url('../../img/index/checklists.svg');
}

.menu-home-icon.account {
    background-image: url('../../img/index/male.svg');
}

.menu-home-icon.schematics {
    background-image: url('../../img/index/schematics.svg');
}

.menu-home-icon.batch-recipes {
    background-image: url('../../img/index/batch-recipes.svg');
}

.menu-home-icon.drink-recipes {
    background-image: url('../../img/index/drink-recipes.svg');
}

.menu-home-icon.management {
    background-image: url('../../img/index/management.svg');
}

.menu-home-icon.admin {
    background-image: url('../../img/index/gears.svg');
}

.menu-home-icon.coffee {
    background-image: url('../../img/index/icon-coffee.png');
}
.menu-home-icon.recipe {
    background-image: url('../../img/index/icon-recipe.png');
}
.menu-home-icon.user {
    background-image: url('../../img/index/icon-user.png');
}
.menu-home-icon.settings {
    background-image: url('../../img/index/icon-settings.png');
}
.menu-home-icon.management {
    background-image: url('../../img/index/icon-management.png');
}
.menu-home-icon.manager {
    background-image: url('../../img/index/icon-manager.png');
}
.landing-page .ui.divided.two.column.grid{
    text-align: center;
}

@media screen and (max-width:1100px){
    .grid-wrapper .menu-item .menu-label {
        font-size: 14px;
    }

}

@media screen and (max-width:767px){
    .grid-wrapper .column {
        width: 100% !important;
    }
}