.vendors-admin-view-header h2 {
    text-transform: uppercase;
    margin-top: 50px;
}

.vendors-admin-view-header-buttons button{
    margin-top: 15px!important;
}

.vendors-admin-view-header-buttons {
    margin-top: 20px;
}

.vendors-admin-view-header-informations {
    margin-top: 20px;
}

.vendors-admin-view-stores-table-div {
    margin-top: 30px!important;
}

.vendors-admin-view-products-table-div {
    margin-top: 30px!important;
}
.vendors-admin-view-stores-card tr:hover {background-color:rgba(225, 225, 225, 0.78);}

.vendors-admin-view-stores-card-header {
    text-align: center;
    height: 40px;
    background-color: rgba(225, 225, 225, 0.78) !important;
    padding-top: 7px!important;
    padding-bottom: 7px!important;
}

.vendors-admin-view-product-col {
    background-color: #e7e7e7;
}

.vendors-admin-view-products-card-header {
    text-align: center;
    height: 40px;
    background-color: rgba(225, 225, 225, 0.78) !important;
    padding-top: 7px!important;
    padding-bottom: 7px!important;
}

.vendors-admin-view-products-card-header-2 {
    text-align: center;
    height: 40px;
    background-color: rgba(156, 175, 218, 0.69) !important;
    padding-top: 7px!important;
    padding-bottom: 7px!important;
}

.vendors-admin-view-table-deactivated-row td {
    color: red;
    opacity: 0.5;
}

.vendors-admin-view-table-actions-td {
    opacity: 1!important;
}

.vendors-admin-view-table {
    border-collapse: collapse;
    width: 100%;
}

.vendors-admin-view-table th, .vendors-admin-view-table td{
    border: 1px solid black;
    text-align: center;
    height: 30px;
}

.vendors-admin-view-table-header {
    background-color: #d3d3d3 !important
}

.vendors-admin-view-table-actions-td {
    background-color: #e0e1e2;
}

.vendors-admin-view-table-link-td a {
    color: #0c85d0;
}

@media only screen and (max-width: 1000px) {
    .vendors-admin-view-table {
        width: 1000px;
    }
    .vendors-admin-view-table-div {
        overflow-x: scroll;
    }
}