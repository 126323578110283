.commentCard {
    margin-bottom: 20px!important;
}

.cardHeader {
    background-color: #d3d3d3!important;
    height: 40px;
}
a.header {
    padding-left: 0!important
}

.shiftCard {
    margin-top: 15px!important;
}

.fileInput input{
    width: 250px!important;
    float: left;
    display: block;
}

.fileInputTypes {
    float: left;
    clear:left
}

.comm-sheet-images img{
    width: 200px!important;
    height: 150px!important;
}
.comm-sheet-videos{
    width: 200px!important;
    height: 150px!important;
}
.comm-sheet-images{
    width: 200px!important;
    height: 150px!important;
}
.comm-sheet-media-div {
    height: 150px!important;
}

.comm-sheet-remove-image-link {
    margin-top: 5px;
    clear: both;
}

.ui.list>.item a.header {
    color: orange!important;
}


@media only screen and (min-width: 768px) and (max-width: 950px) {
    .inputShift input{
        width: 165px;
    }
}

@media only screen and (max-width: 355px) {
    .fileInput>input {
        width: 215px!important;
    }
}
