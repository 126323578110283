/* Table */
.ui.table.user-access-table > tbody > tr > td {
  text-align: center;
}

.ui.table.user-access-table > tbody > tr > td:first-child {
  text-align: left;
}

.ui.table.user-access-table tbody tr td:only-child {
  background: #f9fafb;
}

.ui.table.user-access-table {
  margin-bottom: 86px;
}

.sticky-button-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #f9fafb;
  padding: 15px 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  /* -webkit-box-shadow: 0 0 4px 2px #C2C2C2;
    box-shadow: 0 0 4px 2px #C2C2C2; */
  border-top: 1px solid #ddd;
}

.capitalize-first-letter {
  text-transform: capitalize !important;
}

.subtitle {
  font-size: 16px;
}
