.stores-admin-view-header-description-div h1 {
    text-align: left!important;
}

.stores-admin-view-header-description-div {
    margin-top: 50px;
}

.stores-admin-view-buttons {
    margin-top: 20px;
}
.stores-admin-view-button {
    margin-top: 10px!important;
}

.stores-admin-view-table {
    border-collapse: collapse;
    width: 100%;
}

.stores-admin-view-table th, .stores-admin-view-table td{
    border: 1px solid black;
    text-align: center;
    height: 30px;
}

.stores-admin-view-table-header {
    background-color: #d3d3d3 !important
}

.stores-admin-view-table-actions-td {
    background-color: #e0e1e2;
}

.stores-admin-view-card-actions .selection {
    margin-right: 10px!important;
    margin-bottom: 10px!important;
}

.stores-admin-view-card-actions .checkbox  {
    margin-right: 10px!important;
    margin-bottom: 10px!important;
}

.stores-admin-view-card-actions .button  {
    margin-bottom: 10px!important;
}

.stores-admin-view-store-infos {
    margin-top: 20px;
}

.stores-admin-view-vendors-card-div {
    margin-top: 30px;
    padding-right: 20px;
    width: 60%;
    float: left;
}

.stores-admin-view-users-card-div {
    margin-top: 30px;
    width: 40%;
    float: left;
}

.stores-admin-view-vendors-card-header, .stores-admin-view-users-card-header {
    text-align: center;
    height: 40px;
    background-color: rgba(225, 225, 225, 0.78) !important;
    padding-top: 7px!important;
    padding-bottom: 7px!important;
}

.stores-admin-view-vendors-card-description {
    overflow: inherit!important;
}

.stores-admin-view-vendors-card tr:hover {background-color:rgba(225, 225, 225, 0.78);}

.stores-admin-view-users-card tr:hover {background-color:rgba(225, 225, 225, 0.78);}

@media only screen and (max-width: 1000px) {
    .stores-admin-view-vendors-card-div {
        margin-top: 30px;
        width: 100%;
        float: none;
        padding-right: 0 !important;
    }
    .stores-admin-view-users-card-div {
        margin-top: 30px;
        width: 100%;
        float: none;
        padding-right: 0 !important;
    }
}

@media only screen and (max-width: 800px) {
    .stores-admin-view-table-div {
        overflow-x: scroll;
    }
    .stores-admin-view-table {
        width: 800px;
    }
}