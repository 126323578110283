.orders-history-print-button {
    margin-bottom: 50px;
}

.order-history-table-root-div {
    height: 100%;
}

.order-history-card-header {
    text-align: center;
    height: 40px;
    background-color: rgba(225, 225, 225, 0.78) !important;
}
.order-history-root-div {
    padding-right: 20px!important;
    padding-left: 10px!important;
}


.history-table {
    border-collapse: collapse;
    width: 100%;
    min-width: 768px;
}

.history-table th, .history-table td{
    border: 1px solid black;
    text-align: left;
    height: 30px;
    padding-left: 5px;
}
.history-extra-table {
    border-collapse: collapse;
    width: 100%;
    min-width: 768px;
    background-color: #d3d3d3!important;
}

.history-extra-table-row:hover {
    background-color: #e0e1e2!important;
}

.history-extra-table-header-row {
    background-color: #909090c7 !important
}

.history-extra-table th, .history-extra-table td{
    border: 1px solid black;
    text-align: left;
    height: 30px;
}

.history-table-header-row {
    background-color: #e0e1e2!important;
}

.history-extra-table-root-td {
    padding: 0!important;
}

.order-history-filters-dates {
    text-align: center;
}

.order-history-filters .DateInput_input {
    font-size: 13px;
}

.order-history-filters .DateInput {
    width: 100px;
}

.order-history-filters {
    margin-bottom: 30px;
}

.order-history-date-filters-div-range {
    margin-bottom: 15px!important;
}

.history-table-action-cell {
    text-align: center!important;
    float: left;
}

.history-table-action-cell button {
    background-color: transparent!important;
}

.history-table-date-cell {
    margin-top: 8px;
}

.order-history-date-filters-grid {
    text-align: center;
}

.CalendarDay__selected_start {
    background-color: #909090c7!important;
    border-color: black!important;
}
.CalendarDay__selected_end {
    background-color: #909090c7!important;
    border-color: black!important;
}

.CalendarDay__selected_span  {
    background-color: #CFCFCF!important;
    border-color: black!important;
}

.CalendarDay__hovered_span  {
    background-color: #CFCFCF!important;
    border-color: black!important;
    color: black!important;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid #CFCFCF!important;
}

.DayPicker_weekHeader {
    top: 60px!important;
}

.order-history-date-select {
    margin-right: 10px;
}

@media only screen and (max-width: 343px) {
    .order-history-date-select {
        margin-right: 0px!important;
        margin-bottom: 10px!important;
    }
}
@media only screen and (max-width: 890px) {
    .order-history-table-div {
        overflow-x: scroll;
    }
}
@media only screen and (max-width: 480px) {
    .DateRangePicker_picker {
        left: -47.5px!important;
    }
}
@media only screen and (min-width: 769px) and (max-width: 1050px) {
    .DateRangePicker_picker {
        left: -140px!important;
    }
}
@media only screen and (max-width: 1024px) {
    .order-history-table-root-div {
        overflow-x: scroll;
    }
}
@media only screen and (max-width: 768px) {
    .orders-history-print-button {
        text-align: center;
    }
}