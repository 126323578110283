.incident-report-save-button-div {
  padding: 30px 0;
}

.incident-report-save-button-div .ui.form input {
  min-height: 40px;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: none;
}

.incident-report-save-button-div .ui.form .field > .selection.dropdown {
  min-height: 40px;
}

.table-filter {
  padding: 20px;
  border-radius: 10px;
  background: #f2f2f2;
  border: 1px solid #ddd;
}

.table-filter .ui.button {
  min-height: 40px;
  width: 100%;
}

.visibility-hidden {
  visibility: hidden;
  opacity: 0;
}

.ui.pagination .item {
  justify-content: center;
}

.incident-report-save-button-div .ui.table {
  border-radius: 10px;
  overflow: hidden;
}

.incident-report-save-button-div thead {
  background-color: #d2d2d2;
}

.incident-report-save-button-div tfoot {
  background-color: #f2f2f2;
}

.action-buttons {
  display: flex;
}
.incident-report-view-links {
  margin-top: 20px;
}

.incident-report-view-buttons {
  margin-top: 20px;
}
.incident-report-view-button {
  margin-top: 15px !important;
}
.incident-report-view-header-description-div {
  margin-top: 50px;
}
.incident-report-view-table-div {
  overflow-x: scroll;
}

.incident-report-view-table {
  border-collapse: collapse;
  width: 100%;
}

.incident-report-view-table th,
.incident-report-view-table td {
  border: 1px solid black;
  text-align: left;
  height: 30px;
  padding: 8px;
}

.incident-report-view-table-header {
  background-color: #d3d3d3 !important;
}

.DateRangePickerInput {
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.DateRangePickerInput .DateInput {
  border-radius: 4px;
}

.DateRangePickerInput .DateInput .DateInput_input {
  border: 0 !important;
}
