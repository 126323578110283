/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen
and (min-device-width: 375px)
and (max-device-width: 812px)
and (-webkit-min-device-pixel-ratio: 3) {
    /* CSS specific to iOS devices */
    .visible.transition {
        margin-top: 0 !important;
        display: inline-block !important;
        position: relative;
        top: 0%;
    }
}


/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen
and (min-device-width: 414px)
and (max-device-width: 736px)
and (-webkit-min-device-pixel-ratio: 3) {
    /* CSS specific to iOS devices */
    .visible.transition {
        margin-top: 0 !important;
        display: inline-block !important;
        position: relative;
        top: 0%;
    }
}