.common-table .ui.table {
    border-radius: 10px;
    overflow: hidden;
 }
 
 .common-table thead {
     background-color: #d2d2d2;
 }
 
 .common-table tfoot {
     background-color: #f2f2f2;
 }