.promotion-card {
    margin-bottom: 30px!important;
}

.promotion-card-header {
    background-color: #d3d3d3 !important;
    height: 40px;
    text-align: center;
}

.promotion-card-content {
    text-align: center!important;
}

.promotion-card-content hr {
    width: 90%;
}

.promotion-text-div {
    padding: 20px;
}

.promotion-videos-media-div {
    float: left;
    margin: 20px;
    height: 300px;
}

.promotion-videos {
    max-width: 400px!important;
    max-height: 200px!important;
}

.promotion-images-media-div {
    float: left;
    margin: 20px;
    height: 300px;
}

.promotion-images {
    max-height: 400px!important;
    max-width: 200px!important;
    width: 200px;
}

@media only screen and (max-width: 600px) {
    .promotion-videos-media-div {
        float: none;
    }
    .promotion-images-media-div {
        float: none;
    }
}

@media only screen and (max-width: 700px) {
    .promotion-videos-media-div {
        max-height: 220px;
    }
    .promotion-videos {
        max-width: 220px!important;
    }
    .promotion-images-media-div {
        max-height: 220px;
    }
    .promotion-images {
        max-height: 220px!important;
        max-width: 220px!important;
    }
}