.waste-log {
    height: 100%;
}

.waste-log-content {
    text-align: center;
    margin-top: 20px;
}

.wastelog-header-row {
    text-align: left;
    background-color: #fff0e6!important;
    margin-top: 10px!important;
}

.waste-logs-print-button {
    width: 100%;
}

.waste-log hr{
    border: none!important;
    clear:both;
}

.wastelogs-rows .input {
    width: 100% !important;
}

.waste-logs-input-forms {
    margin-top: 20px;
}
.logs-buttons {
    margin-top: 20px;
}



