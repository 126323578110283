.documents-grid {
    margin-right: 15px!important;
    margin-left: 15px!important;
    margin-top: 40px!important;
}

.documents-grid-header-row {
    text-align: left;
    background-color: rgba(197, 197, 197, 0.78) !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    height: 40px;
}

.documents-grid-row {
    height: 40px;
    padding: 5px!important;
    border-left: 1px solid rgba(197, 197, 197, 0.78);
    border-right: 1px solid rgba(197, 197, 197, 0.78);
}

.documents-grid-row:hover {background-color: rgba(216, 216, 216, 0.78);}

.documents-grid-footer-row {
    text-align: left;
    background-color: rgba(238, 238, 238, 0.78) !important;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top: 0;
    border-right: 1px solid rgba(197, 197, 197, 0.78);
    border-left: 1px solid rgba(197, 197, 197, 0.78);
    border-bottom: 1px solid rgba(197, 197, 197, 0.78);
    height: 44px;
    padding: 4px!important;
}

.documents-card-check-button-div {
    margin: 20px!important;
    text-align: center;
}

.documents-card-header {
    text-align: center;
    height: 40px;
    background-color: rgba(225, 225, 225, 0.78) !important;
}

.documents-card-grid-row {
    height: 40px;
    padding: 5px!important;
}

.documents-grid-footer-row .plus {
    background-color: #e0e1e2!important;
}

.documents-grid-footer-row .button:hover {
    background-color: #e0e1e2!important;
}

.documents-card-grid-row a{
    color: #0e8c8c;
    cursor: pointer;
}

.documents-grid-row a{
    color: #0e8c8c;
    cursor: pointer;
}