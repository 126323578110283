.comm-sheet {
    height: 100%;
}

.header {
    margin-bottom: 20px;
}

.comm-sheet-content {
    text-align: center;
    margin-top: 20px;
}

.textEditor .public-DraftStyleDefault-block{
    min-height: 160px;
}