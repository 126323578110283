/* Burger Menu */

/* Individual item */
.bm-item {
    display: inline-block;

    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
    color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
    z-index: 2000 !important;
    position: fixed;
    width: 30px;
    height: 26px;
    left: 15px;
    top: 13px;
}

.bm-burger-button:hover {
    opacity: 0.7;
}

.bm-burger-button > button:active, .bm-burger-button > button:focus {
    outline: none;
    border: 0;
    -moz-outline-style: none;
}

.bm-menu-wrap {
    padding-top: 50px;
    /*z-index: 1 !important;*/
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #fff;
    height: 3px !important;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
    background: transparent;
}

.bm-item.menu {
    outline: none!important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, .15) !important;
}


/* Close Icon */

.close {
    position: fixed;
    top: 8px;
    width: 30px;
    height: 30px;
}

.close:before, .close:after {
    position: fixed;
    left: 29px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #fff;
}

.close:before {
    transform: rotate(45deg);
}

.close:after {
    transform: rotate(-45deg);
}


/* Menu Items */

.ui.vertical.menu {
    height: 100%;
    box-shadow: none;
}

div.item.active {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}

.ui.menu .item {
    padding-left: 0;
    padding-right: 0;
}

.ui.vertical.menu .item > .header:not(.ui) {
    font-family: "NeutraBook", "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.1rem;
    text-transform: uppercase;
    text-align: left;
    margin: 10px 0 0 0;
}

.ui.vertical.menu .item {
    text-align: left;
}

.ui.vertical.menu .item:hover {
    background: rgba(0, 0, 0, .10) !important;
}

.ui.vertical.menu .menu .item {
    font-size: 1.1rem;
}

.ui.vertical.menu > .item:first-child {
    border: 0;
    border-radius: 0;
}

.ui.vertical.menu > .active.item:first-child {
    border: 0;
    border-radius: 0;
}

.ui.menu {
    border: 0;
    border-radius: 0;
}

.item a {
    padding-left: 58px !important;
}

.header {
    padding: 0 10px !important;
}

.header.title {
    padding: 0 10px !important;
}

.ui.link.menu .item:hover, .ui.menu .dropdown.item:hover, .ui.menu .link.item:hover, .ui.menu a.item:hover {
    background: rgba(0, 0, 0, .10);
}

.ui.menu .active.item:hover, .ui.vertical.menu .active.item:hover {
    background: rgba(0, 0, 0, .10);
}

.ui.vertical.menu .active.item {
    background: rgba(0, 0, 0, .10);
}

.icon.menu-chevron {
    float: right;
    margin-top: 7px;
}

.menu-header-icon {
    width: 35px;
    height: 35px;
    vertical-align: middle;
    display: inline-block;
    margin-right: 15px;
}

.menu-header-icon.check-lists {
    background: url('../../img/index/checklists.svg') no-repeat;
}

.menu-header-icon.account {
    background: url('../../img/index/male.svg') no-repeat;
}

.menu-header-icon.schematics {
    background: url('../../img/index/schematics.svg') no-repeat;
}

.menu-header-icon.batch-recipes {
    background: url('../../img/index/batch-recipes.svg') no-repeat;
}

.menu-header-icon.drink-recipes {
    background: url('../../img/index/drink-recipes.svg') no-repeat;
}

.menu-header-icon.management {
    background: url('../../img/index/management.svg') no-repeat;
}

.menu-header-icon.admin {
    background: url('../../img/index/gears.svg') no-repeat;
}

.menu-header-icon.recipes {
    background: url('../../img/index/batch-recipes.svg') no-repeat;
}
