h1.login-h1 {
    margin-bottom: 2em;
    font-size: 1.7rem;
    font-family: "NeutraBold", "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
}

sup {
    font-size: 1rem;
}

.login-content {
    max-width: 930px;
    margin: 0 auto;
    padding: 0 1.8rem;
}

.login-content-background {
    background-color: #f47321;
}

h1.ui.header.logo {
    text-indent: -10000em;
    font-size: 0.01em;
    background-image: url('../../img/sprites/ui-sf4935c749f.png');
    background-position: 0 -73px;
    background-repeat: no-repeat;
    overflow: hidden;
    display: block;
    height: 73px;
    width: 153px;
    margin: 16.2% auto 9% auto;
}
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 3 / 2), (min-device-pixel-ratio: 2) {
    h1.ui.header.logo {
        background-image: url('../../img/sprites/ui-2x-s3bc90e0710.png');
        background-size: 201.5px 269.5px;
        background-position: 0 -70px;
        height: 73px;
        width: 153px;
    }
}

.ui.segment {
    border-radius: .6rem;
    padding: 2rem 4rem;
    text-align: left;
    overflow: hidden;
}

.ui.checkbox label {
    font-weight: bold;
}

.ui.form input[type="text"] {
    color: #888;
    background-color: #eee;
    border: none;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.ui.form input[type="text"]:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.ui.form input[type="password"] {
    color: #888;
    background-color: #eee;
    border: none;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.ui.form input[type="password"]:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

figure {
    margin: 1rem auto;
}

figure.glasses {
    background-image: url('../../img/sprites/ui-sf4935c749f.png');
    background-position: 0 0;
    background-repeat: no-repeat;
    overflow: hidden;
    display: block;
    height: 67px;
    width: 201px;
}
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 3 / 2), (min-device-pixel-ratio: 2) {
    figure.glasses {
        background-image: url('../../img/sprites/ui-2x-s3bc90e0710.png');
        background-size: 201.5px 269.5px;
        background-position: 0 0;
        height: 67px;
        width: 201.5px;
    }
}

figure.mustache {
    background-image: url('../../img/sprites/ui-sf4935c749f.png');
    background-position: 0 -152px;
    background-repeat: no-repeat;
    overflow: hidden;
    display: block;
    height: 57px;
    width: 174px;
}
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 3 / 2), (min-device-pixel-ratio: 2) {
    figure.mustache {
        background-image: url('../../img/sprites/ui-2x-s3bc90e0710.png');
        background-size: 201.5px 269.5px;
        background-position: 0 -146px;
        height: 57.5px;
        width: 174.5px;
    }
}
