.retail-sheet-footer {
    display: flex;
}

.retail-sheet-footer h3 {
    text-align: right;
    margin-bottom: 30px;
}

.retail-sheet-footer h4 small {
    display: block;
}

.half-col {
    width: 50%;
}

.points-docked-wrapper {
    text-align: right;
}

.points-docked-wrapper .table {
    margin-left: auto;
    width: 40%;
}

@media (max-width: 768px) {
    .retail-sheet-footer .ui.button {
        margin-top: 14px;
    }

    .retail-sheet-footer {
        flex-direction: column-reverse;
    }

    .points-docked-wrapper .table {
        width: 100%;
    }

    .half-col {
        width: 100%;
    }

}