.products-admin-filters-buttons {
    margin-top: 30px;
}

.products-admin-add-button {
    margin-top: 30px;
}

.products-admin-cards {
    margin-top: 30px;
}

.products-admin-table-header {
    background-color: #d3d3d3 !important;
}

.products-admin-table-action-header {
    background-color: #d3d3d3 !important;
    width: 215px!important;
}

.products-admin-table-action-header-deactivated {
    background-color: #d3d3d3 !important;
    width: 270px!important;
}

.products-admin-table {
    border-collapse: collapse;
    width: 100%;
}

.products-admin-table th, .products-admin-table td{
    border: 1px solid black;
    text-align: center;
    height: 30px;
}

.products-admin-table a {
    color: #0e8c8c;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .products-admin-table-div {
        overflow-x: scroll;
    }

    .products-admin-table {
        width: 1300px;
    }
}